@import './vars';


@media screen and (max-width: 1440px) {
}


@media screen and (max-width: 1024px) {
    .headLstHeader_menu{
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    } 
    .headLstMobile__button{
        display: flex;
        justify-self: end;
    }
    .lastNce_track-info, .lastNce_track-central{
        height: 70px;
    }
    .lastNce_mute-box__muteUnmute{
        display: none;
    }
    .headLstHeader_menu a{
        margin-top: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .lastNce_mute-box{
        display: none;
    }
    .lastNce_letter__link{
        background: var(--color-3);
    }
}

@media screen and (max-width: 992px) {
    span.lastNce_shareblock{
        left: 25%;
    }
    .bodMainDownNetwork_vk{
        width: 220px;
    }
    #dif_inst-request__form{
        width: 400px;
    }
    .lastNce_melodious__list a{
        font-size: 24px;
    }
    .lastNce_track-info{
        margin-right: 60px;
    }
    }
@media screen and (max-width: 930px) {
    .lastNce_copyright_box-mail{
        flex-direction: column;
    }
    .lastNce_copyright_box-mail svg{
        margin: 5px 0 5px;
    }
}

@media screen and (max-width: 768px) {
    .headLstHeader_contmenu a+*{
        margin-left: 0;
    }
    .headLstHeader_contmenu{
        margin-bottom: 20px;
    }
    .headLstHeader_contmenu a{
        margin-right: 15px;
        margin-left: 15px;
        font-size: 30px;
        margin-bottom: 10px;
    }
    .dif_inst-request{
        width: 100%;
    }
    .bodMainSocNetwork{
        flex-direction: column-reverse;
    }
    .bodMainDownNetwork_vk{
        width: 100%;
    }
    .bodMainSocNetwork_vk{
        width: 100%;
    }
    #dif_inst-request__form {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .bodMain-main{
        padding: 40px 15px;
    }
    .lastNce_active__selection-content::after {
        position: absolute;
        width: 100%;
        height: 3px;
        background: var(--color-1);
        content: '';
        left: 0;
        top: -15px;
    }
    .headLstMobile__button-line{
        width: 50px;
        margin-top: 5px;
    }
    .headLstHeader__logo-link{
        z-index: 9999;
        font-size: 45px;
    }
    .bodMainSidebar{
        width: 100%;
    }
    .bodMainPopPop_title{
        font-size: 24px;
    }
    .lastNce_player-title_heading{
        margin-right: 0;
    }
    .lastNce_track__leftWrapper{
        justify-self: end;
    }
    .lastNce_results-info__card{
        padding: 15px 10px;
    }
    .lastNce_melodious__list{
        flex-direction: row;
    }
    .lastNce_melodious__list a{
        margin-right: 5px;
        margin-left: 5px;
    }
    .lastNce_boxline__bottom{
        text-align: center;
    }
    .lastNce_mute-box{
        margin-right: 30px;
    }
    .lastNce_about{
        flex-direction: column;
    }
    .lastNce_logo-link{
        display: none;
    }
    .lastNce_wrapper{
        margin-left: 0;
    }

    .lastNce_mobile-nav__button{
        width: min-content;
        justify-self: end;
    }
    .lastNce_song-aplhabet{
        width: auto;
    }
    .lastNce_popular_title{
        font-size: 25px;
    }
    .lastNce_popular_logo{
        width: 40px;
        height: 40px;
    }
    .lastNce_sidebar{
        display: none;
    }
    .lastNce_wrapper{
        width: 100%;
    }
    .lastNce_melodious__list a::before{
        display: none;
    }
    .lastNce_footer_copy{
        width: 100%;
    }
}

@media screen and (max-width: 730px) {
    .lastNce_copyright_box-mail {
        flex-direction: column;
    }
    .lastNce_copyright_box-mail svg{
        margin-left: 0;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

@media screen and (max-width: 624px) {
    .track-songCard_star{
        display: none;
    }
    .lastNce_copyright_box-mail a{
        margin-left: 0;
    }
    .track{
        flex-direction: column;
    }
    .lastNce_copyright_box{
        margin-bottom: 150px;
    }
    .social-box{
        right: 30px;
    }

    .lastNce_about{
        margin-bottom: 190px;
    }
    .lastNce_track-central{
        margin-right: 0;
    }
    .lastNce_track-info{
        margin-bottom: 10px;
    }
    .lastNce_mute-box {
        width: auto;
        justify-self: center;
        grid-column-start: 2;
        margin-right: 0;
    }
    .lastNce_zero-time{
        font-size: 16px;
    }
    .lastNce_time_space{
        font-size: 16px;
    }
    .lastNce_timeBox{
        font-size: 16px;
    }
    .lastNce_copyright_box-mail{
        flex-direction: column;
    }
}

@media screen and (max-width: 670px) {
    .lastNce_melodious__list a{
        justify-content: center;
    }
}

@media screen and (max-width: 577px) {

    .lastNce_popular_logo{
        display: none;
    }
    .lastNce_popular_title{
        text-align: center;
    }
    .lastNce_sotial-network-vk_btn{
        width: 100%;
        margin-bottom: 20px;
    }
    .lastNce_sotial-network-vk_link{
        width: 100%;
    }
    .lastNce_sotial-network-vk_input{
        flex-direction: column;
    }


    .lastNce_download_vk{
        width: 100%;
    }
    .lastNce_vk{
        width: 100%;
        min-width: auto;
    }
    .lastNce_social-box{
        padding-bottom: 0;
    }
    .lastNce_player-title_heading{
        margin-right: 0;
    }
    .lastNce_results-info__card_download{
        width: 40px;
        height: 40px;
    }
}    


@media screen and (max-width: 510px) {
    .lastNce_copyright_box{
        margin-bottom: 190px;
    }
    .pleer{
        padding-bottom: 60px;
    }
    .lastNce_track-info{
        margin-right: 0;
    }
    .lastNce_player-title{
        margin-top: 0;
    }
    .lastNce_copyright_box-mail{
        font-size: 28px;
        margin-top: 10px;
    }
    .lastNce_player-title_heading{
        text-align: center;
        margin-top: 10px;
    }
    .progress-bar__container{
        flex-direction: column;
    }
    .bodMainSocial_vk{
        display: none;
    }
    .res-card_st-bf_results-info__card-subtext, .lastNce_subinfo__time{
        display: none;
    }
    .lastNce_player-title{
        text-align: center;
        margin-left: 0;
        justify-content: center;
        margin-bottom: 0;
    }
    .lastNce_description{
        margin-bottom: 20px;
    }
    .track{
        display: flex;
        flex-direction: column;
    }
    .lastNce_mute-box{
        display: none;
    }
    .lastNce_track-info{
        flex-direction: column;
        align-items: center;
    }
    .lastNce_track-central{
        margin-bottom: 10px;
    }

}

@media screen and (max-width: 475px) {
    .lastNce_letter__logo{
        display: none;
    }
    .track-songCard_artist{
        margin-bottom: 10px;
    }
    .bodMainContainer{
        padding: 0 15px;
    }
    .lastNce_description_content{
        display: none;
    }
    .bodMainPopPop__box{
        display: none;
    }
    .lastNce_player-title_heading{
        font-size: 19px;
    }
    .headLstHeader_contmenu a{
        font-size: 32px;
        letter-spacing: 2px;
    }
    .title{
        margin-right: 15px;
        align-items: flex-start;
        flex-direction: column;
        font-size: 18px;
    }
    .track-songCard_space{
        display: none;
    }
    .track-songCard_artist, .track-songCard_tracklink{
        max-height: 20px;
    }
    .track-songCard_artist{
        margin-right: 0;
    }
}

@media screen and (max-width: 425px) {
    .track-songCard .start{
        width: 80px;
    }
    .ya-share2 .ya-share2_inited{
        margin-bottom: 180px;
    }
    .headLstHeader_contmenu{
        flex-direction: column;
    }
    .lastNce_sotial-network-vk_btn, .lastNce_sotial-network-vk_link{
        width: calc(100% - 30px);
    }
    .lastNce_sotial-network-vk_content{
        margin: 15px;
        border-radius: 0;
    }
    .lastNce_story{
        margin-top: 30px;
    }
    .lastNce_story{
        display: none;
    }
    .bodMain-main{
        padding: 0 !important;
        border-radius: 0;

    }
    .headLstMobile__button-line:last-child{
        margin-bottom: 0;
    }
    .headLstHeader__logo-link{
        font-size: 37px;
    }
    .headLstMobile__button{
        margin-left: 15px;
        padding: 0;
    }
    .headLstMobile__button-line{
        width: 40px;
        margin-bottom: 8px;
        margin-top: 0;
        
    }
    .lastNce_active__selection-content, .lastNce_music__title{
        margin-bottom: 10px;
        font-size: 32px !important;
    }
    .lastNce_genre-item_logo{
        display: none;
    }
    .bodMainSidebar{
        padding: 100px 15px 15px 15px;
    }
    .lastNce_description_title{
        display: none;
    }
    .music-artist__card_artist, .music-artist__card_tracklink{
        max-height: 17px;
    }
    .headLstHeader_menu{
        flex-direction: column;
        align-items: center;
    }
    .headLstHeader_menu a{
        font-size: 24px;
    }
    .music-artist__card_download svg{
        width: 40px;
        height: 25px;
    }
    .music-artist__card_download{
        width: 40px;
        height: 20px;
    }
    .music-artist__card{
        height: 70px;
        padding: 10px 5px 5px 5px;
    }
    .music-artist__card_time{
        display: none;
    }
    .music-artist__card .start{
        transform: scale(0.6);
    }
    .music-artist__card_download{
        position: static;
    }
    .music-artist__card-subtext{
        display: none;
    }
    .res-card_st-bf_results-info__card{
        padding: 15px 10px;
    }
    .lastNce_popular__box{
        justify-content: center;
    }
    .lastNce_melodious__heading{
        text-align: center;
    }
    .lastNce_description_title{
        text-align: center;
    }
    .lastNce_subinfo__time{
        display: none;
    }
    .lastNce_results-info__card_time{
        margin-right: 0;
    }
    .lastNce_results-info__card-subtext{
        margin-right: 10px;
        margin-left: 15px;
    }
    .lastNce_results-info__card_artist, .lastNce_results-info__card_space, .lastNce_results-info__card_tracklink{
        font-size: 14px;
    }    
    .lastNce_results-info__card_download svg{
        width: 30px;
        height: 30px;
    }
    .lastNce_results-info__card.pausing a.lastNce_results-info__card_link::before {
        content: url(../images/musicTheme-play.svg);
        padding-left: 12px;
        padding-right: 8px;
        height: 22px;
    }
    .lastNce_results-info__card_link::before{
        width: 20px;
        height: 20px;
        padding: 11px;
    }
    .lastNce_results-info__card .start{
        width: 40px;
        height: 40px;
    }
    .lastNce_melodious__list{
        display: flex;
        flex-direction: column;
    }
    .lastNce_melodious__list a{
        font-size: 31px;
    }
    .headLstHeader_logo-link{
        font-size: 30px;
    }
}

@media screen and (max-width: 375px) {
    .track-songCard_link{
        margin-right: 5px;
    }
    .track-songCard{
     padding: 15px 10px;   
    }
    .bodMainContainer{
        padding: 0 10px;
    }
    .lastNce_letter__title{
        text-align: center;
    }
} 