


a{
	text-decoration: none;
}

p{
	margin: 0;
	padding: 0;
}

.clr {
	clear: both;
}

h1 {
	font-size: 36px;
}


.ya-share2__container, .ya-share2__container .ya-share2__item{
	display: flex;
	justify-content: center;
}

.ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item{
	margin-right: 10px !important;
	margin-left: 10px !important;
}

.nextpage:hover,
.prevpage:hover {
	background-color: #29b6f6;
	color: #fff;
}

a.lyrics {
	position: absolute;
	right: 125px;
	top: 15px;
	cursor: pointer;
	width: 24px;
	height: 24px;
	display: inline-block;
	background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU4IDU4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1OCA1ODsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yLDkuNWg1NGMxLjEwNCwwLDItMC44OTYsMi0ycy0wLjg5Ni0yLTItMkgyYy0xLjEwNCwwLTIsMC44OTYtMiwyUzAuODk2LDkuNSwyLDkuNXoiIGZpbGw9IiMwMDAwMDAiLz4KCQk8cGF0aCBkPSJNMiwyMy41aDU0YzEuMTA0LDAsMi0wLjg5NiwyLTJzLTAuODk2LTItMi0ySDJjLTEuMTA0LDAtMiwwLjg5Ni0yLDJTMC44OTYsMjMuNSwyLDIzLjV6IiBmaWxsPSIjMDAwMDAwIi8+CgkJPHBhdGggZD0iTTIsMzguNWg1NGMxLjEwNCwwLDItMC44OTYsMi0ycy0wLjg5Ni0yLTItMkgyYy0xLjEwNCwwLTIsMC44OTYtMiwyUzAuODk2LDM4LjUsMiwzOC41eiIgZmlsbD0iIzAwMDAwMCIvPgoJCTxwYXRoIGQ9Ik0yLDUyLjVoMzJjMS4xMDQsMCwyLTAuODk2LDItMnMtMC44OTYtMi0yLTJIMmMtMS4xMDQsMC0yLDAuODk2LTIsMlMwLjg5Niw1Mi41LDIsNTIuNXoiIGZpbGw9IiMwMDAwMDAiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K');
	background-repeat: no-repeat;
	background-position: 4px 4px;
	opacity: 0.7;
	transition: all 0.2s ease;
}

a.lyrics:hover {
	opacity: 1;
}

span.lyrics {
	height: auto;
	display: block;
	z-index: 10;
	padding: 0 0 20px 40px;
	font-size: 0.8em;
}

span.lyrics img {
	width: 32px;
	border-radius: 40px;
}

span.lyrics.inv {
	display: none;
}

.goge {
	display: none;
}

.trackLink {
	font-size: 1em;
	font-weight: normal !important;
}



p.blockContent {
	padding: 20px;
	border: 1px solid red;
	margin: 50px;
	border-radius: 6px;
	font-weight: bold;
}
