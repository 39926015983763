:root{
    --color-1: #1c7364;
    --color-2: #9bddd1; 
    --color-3: #ccc4b4;
    --color-4: var(--color-1);
    --color-5: #545a29;
    --color-6: #a5b4cf;
    --color-7: #3a425d;


    --color-8: #fac87c;
    --color-10: var(--color-background);
    --background: #edeef0;
    
    --color-14: #fff;
    --white-color: #fff;
}